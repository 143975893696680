<template>
  <div class="error">
    <div>
      <h1>404</h1>
      <p>You have reached an unknown destination</p>
      <div class="mt-5 mb-5">
        <a href="/">
          <button>Back to Home Page</button>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.error {
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: whitesmoke;
}

.error div {
  display: grid;
  place-items: center;
}

.error div img {
  width: 100px;
}

.error h1 {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 80px;
}

button {
  width: 187px;
  height: 52px;
  font-weight: 300;
  font-size: 13px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.005em;
  color: #3a3a3c;
  border: none;
  border-radius: var(--button-border-radius);
  cursor: pointer;
}
</style>